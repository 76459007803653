<header class="banner">
  <div class="container position-relative">
    <h1>IL BLOG TEACAR</h1>
    <p class="mb-0 link-back">
      <a routerLink="" class="back-article">Indietro</a>
    </p>
  </div>
</header>
<!-- <header class="banner">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
      </div>
    </div>
  </div>
  <img src="{{detail[0]?.Blog.Base64}}"  alt="{{detail[0]?.Blog.Title}}"
  *ngIf="detail[0]?.Blog.Base64">
</header> -->
<section>
  <div class="container position-relative">
    <p class="mb-0 link-back"><a routerLink="" >Indietro</a></p>
    <p class="date-article"><b>{{detail[0]?.Blog.DtInsert | date: 'dd/MM/yyyy'}}</b></p>
    <div class="col-lg-10 mx-auto text-center">
      <h1 class="title-article">{{detail[0]?.Blog.Title}}</h1>
      <h2 class="mt-4 subtitle-article"> {{detail[0]?.Blog.Subtitle}}</h2>

    </div>
    <img src="{{detail[0]?.Blog.Base64}}" class="img-fluid img-blog my-4 m-0"
    *ngIf="detail[0]?.Blog.Base64">
    <div class="col-lg-10 mx-auto">
      <p class="my-4 bodyy" [innerHTML]="blogBody"></p>
    </div>

    <div *ngIf="detail[0]?.Attachments.length!=0">
      <div *ngFor="let item of detail[0]?.Attachments">
        <img [src]="item.Base64" [alt]="item.Caption" *ngIf="item.FileExt=='.jpg' ||item.FileExt=='.jepg' ||item.FileExt=='.png' ">
      </div>
    </div>
    <div *ngIf="detail[0]?.Attachments.length!=0">
      <ng-container *ngFor="let item of detail[0]?.Attachments">
        <div class="d-flex attach align-items-center mb-2" *ngIf="item.FileExt!='.jpg' && item.FileExt!='.jepg' &&item.FileExt!='.png' ">
          <a href="{{item.Link}}" target="_blank">{{item.Caption}}</a>
          <span>{{item.Link}}</span>
        </div>
      </ng-container>
    </div>

    <div class=" box-share-article">
      <p>CONDIVIDI SU</p>
      <div class="d-flex justify-content-evenly w-100 mt-4">
        <a (click)="social('whatsapp')"><img src="assets/img/wtsp.svg" alt="whatsapp"></a>

        <a (click)="social('facebook')"><img src="assets/img/facebook-w.svg" alt="facebook"></a>

        <a (click)="social('linkedin')"><img src="assets/img/linkedin.svg" alt="linkedin"></a>

        <a (click)="social('email')"><img src="assets/img/email.svg" alt="email"></a>
      </div>
    </div>
  </div>
</section>

<div itemscope itemtype="https://schema.org/NewsArticle" style="position: absolute;bottom: 0;visibility: hidden;z-index: -10;">
  <div itemprop="headline">{{detail[0]?.Blog.Title}}</div>
  <!-- <meta itemprop="image" content="{{detail[0]?.Blog.Base64}}" />
  <meta itemprop="image" content="{{detail[0]?.Blog.Base64}}" /> -->
  <!--<img itemprop="image" src="https://example.com/photos/16x9/photo.jpg" /> -->
  <div>
    <span itemprop="datePublished">
      {{detail[0]?.Blog.DtInsert | date: 'yyyy-MM-ddTHH:mm:ssZ'}}
    </span>
    (last modified
    <span itemprop="dateModified">
      {{detail[0]?.Blog.DtUpdate | date: 'yyyy-MM-ddTHH:mm:ssZ'}}
    </span>
    )
  </div>
  <div>
    <span itemprop="author" itemscope itemtype="https://schema.org/Organization">
      <a itemprop="url" href="https://www.teacarservice.it/">
        <span itemprop="name">Teacar srl</span>
      </a>
    </span>
  </div>
</div>