import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Blog';
  menuOpen: boolean = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const btnMenu = document.getElementById('btn-menu') as HTMLImageElement;
    const fullPageMenu = document.getElementById('full-page-menu') as HTMLElement;
    const logoMobile = document.getElementById('logo-mobile') as HTMLElement;
    const navMobile = document.getElementById('nav-mobile') as HTMLElement;

    if (btnMenu) {
      this.renderer.listen(btnMenu, 'click', () => {
        this.toggleMenu(btnMenu, fullPageMenu, logoMobile, navMobile);
      });
    }

    if (fullPageMenu) {
      this.renderer.listen(fullPageMenu, 'click', () => {
        this.hideMenu(fullPageMenu);
      });
    }
  }

  toggleMenu(
    btnMenu: HTMLImageElement,
    fullPageMenu: HTMLElement,
    logoMobile: HTMLElement,
    navMobile: HTMLElement
  ): void {
    this.menuOpen = !this.menuOpen; // Toggle the state of the menu

    if (this.menuOpen) {
      btnMenu.src = '../assets/img/x-menu-mobile.svg'; // Change the menu image
      fullPageMenu.style.left = '0';

      if (window.scrollY === 0) {
        logoMobile.style.opacity = '1';
        navMobile.style.backgroundColor = '#ffffffd1';
      }
    } else {
      btnMenu.src = '../assets/img/btn-menu.svg'; // Restore the original menu image
      fullPageMenu.style.left = '-100%';

      if (window.scrollY === 0) {
        navMobile.style.backgroundColor = '#ffffffb5';
        navMobile.style.borderBottom = '1px solid transparent';
      }
    }
  }

  hideMenu(fullPageMenu: HTMLElement): void {
    fullPageMenu.style.left = '-100%'; // Hide the menu by moving it off-screen to the left
  }
}
