import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsComponent } from './news/news.component';
import { ArticleComponent } from './article/article.component';

const routes: Routes = [
  { path: '', component: NewsComponent },
  { path: 'pagina/:numpage', component: NewsComponent },
  { path: ':id', component: ArticleComponent },
  { path: ':id/:name?', component: ArticleComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [    RouterModule.forRoot(routes,  { scrollPositionRestoration: 'enabled' }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
