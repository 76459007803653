<nav>
    <div class="top-nav">
        <div class="container d-flex w-100 justify-content-lg-between h-100 align-items-center text-center justify-content-center">
            <p class="d-none d-lg-block">Lun-Ven: 08.00 - 12.00 / 14.00 - 18.00 e Sab: 08.00 - 12.00</p>
            <p class="d-flex d-lg-none align-items-center"> <img src="../assets/img/time-top-nav.svg" alt="time" class="me-3">Lun-Ven: 8 - 12 / 14 -
                18 e Sab: 8 - 12</p>
            <a class="box-prenota " href="prenotazione.html">
                <img src="assets/img/icon-prenota.svg" alt="Prenota" class="pe-2">
                <p> Prenota Online</p>
            </a>

        </div>
    </div>
    <div class="primary-nav nav-index">
        <div class="navbar navbar-expand-lg">
            <div class="container">
                <a class="navbar-brand" href="https://www.teacarservice.it/"><img src="../assets/img/logo.svg" alt="Logo Teacar"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav ">
                        <li class="nav-item">
                            <a class="nav-link " aria-current="page" href="https://www.teacarservice.it">HOME</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.teacarservice.it/chi-siamo.html">CHI SIAMO</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.teacarservice.it/servizi.html">SERVIZI</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.teacarservice.it/contatti.html">CONTATTI</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">BLOG</a>
                        </li>
                    </ul>
                    <div class=" box-tool-nav ps-5">
                        <a href="https://maps.app.goo.gl/JT4tAP26NRtccGwo9" target="_blank">
                            <img src="../assets/img/map-pointer.svg" alt="Mappa Dove siamo">
                        </a>
                        <div class="vr mx-3"></div>
                        <a href="tel:+39039617976">
                            <img src="../assets/img/tel.svg" alt="Telefono">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" nav-mobile nav-mobile-home" id="nav-mobile">
        <div class="d-flex justify-content-between align-items-center h-100 container position-relative z-2">

            <a href="https://www.teacarservice.it/"><img src="../assets/img/logo.svg" alt="Logo teacar" class="logo-mobile" id="logo-mobile"></a>
            <img src="../assets/img/btn-menu.svg" alt="Menu" id="btn-menu">
        </div>
        <div id="full-page-menu">
            <div class="menu-mobile z-1">
                <p class="title-menu ">MENÙ</p>
                <a href="https://www.teacarservice.it/">Home</a>
                <a href="https://www.teacarservice.it/chi-siamo.html">Chi Siamo</a>
                <a href="https://www.teacarservice.it/servizi.html">Servizi</a>
                <a href="https://www.teacarservice.it/prenotazione.html#/prenotazione">Prenota Online</a>
                <a href="https://www.teacarservice.it/contatti.html">Contatti</a>
                <a href="">Blog</a>
                <hr class="w-100 my-5">
                <div class="box-phone-nav-mobile">
                    <img src="../assets/img/tel-mobile.svg" alt="Telefono">
                    <a href="tel:+39039617976">+39 039 617976</a>
                </div>
            </div>
        </div>
    </div>
</nav>
<main>
  <router-outlet #o="outlet"></router-outlet>  
</main>
<footer id="footer-dynamic">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 text-center d-flex align-items-center justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                <img src="assets/img/logo-white.svg" alt="Logo Teacar" loading="lazy" class="logo-footer">
            </div>
            <div class="col-lg-3 text-center text-lg-start mt-3 mt-lg-0">
                <p>Partita IVA 00692120967 <br>REA MB - 744185 <br>Capitale Sociale in € 10.000
                </p>
    
            </div>
            <div class="col-lg-2 text-center text-lg-start mt-3 mt-lg-0">
                <p class="mt-3 mt-lg-0">
                    SEDE LEGALE<br>
                    Via Del Bruno, 38 <br>Arcore MB 20862
                </p>
            </div>
            <div class="col-lg-3 text-center text-lg-start mt-3 mt-lg-0">
                <p>CONTATTI<br>
                    T <a href="tel:+39039617976">+39 039 617976</a><br>
                    M <a href="mailto:info@teacarservice.it">info&#64;teacarservice.it</a>
                </p>
            </div>
            <div class="col-lg-2 text-center text-lg-end mt-3 mt-lg-0">
                <p>Seguici su
                </p>
                <div class="d-flex justify-content-center justify-content-lg-end social-icon">
                    <a href="https://www.instagram.com/teacarservice/" target="_blank"><img src="assets/img/instagram.svg" alt="instagram"></a>
                    <a href="https://www.facebook.com/teacarservice/" target="_blank"><img src="assets/img/facebook.svg" alt="facebook" class="mx-2"></a>
                    <a href="https://www.linkedin.com/company/teacarservice/" target="_blank"><img src="assets/img/linkedin.svg" alt="linkedin"
                            class="me-2"></a>
                    <a href="https://youtube.com/@teacarservice2639?si=V6t7aZht9S_B3O7m" target="_blank"><img src="assets/img/youtube.svg"
                            alt="youtube"></a>
                </div>
            </div>
        </div>
        <hr class="my-5 ">
        <div class="row">
            <div class="col-lg-4 text-center text-lg-start">
                <p>© 2024 Tea Car Service SRL</p>
            </div>
            <div class="col-lg-4 text-center my-3 my-lg-0">
                <p><a href="https://www.teacarservice.it/privacy.html" target="_blank">Privacy & Cookie Policy</a></p>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <p>Powered by <a href="https://www.itd-italia.com/" target="_blank">ITD SRL</a></p>
            </div>
        </div>
    </div>
</footer>
<script>
    var menuOpen = false;
    $(document).ready(function () {

        $('#btn-menu').click(function () {
            menuOpen = !menuOpen; // Cambia lo stato del menuClicked ad ogni click
            // Mostra il menu spostandolo a sinistra
            if (menuOpen) {
                $('#btn-menu').attr('src', '../assets/img/x-menu-mobile.svg'); // Cambia l'immagine del menu
                $('#full-page-menu').css('left', '0');
                // $('#social-desktop').hide();
                if (window.scrollY == 0) {
                    $('#logo-mobile').css('opacity', '1');
                    $('#nav-mobile').css('background-color', '#ffffffd1');
                }
            } else {
                $('#btn-menu').attr('src', '../assets/img/btn-menu.svg'); // Ripristina l'immagine originale del menu
                $('#full-page-menu').css('left', '-100%');
                // // $('#social-desktop').show();
                if (window.scrollY == 0) {
                    $('#nav-mobile').css('background-color', '#ffffffb5');
                    $('#nav-mobile').css('border-bottom', '1px solid transparent');
                }

            }
        });

        $('#full-page-menu').click(function () {
            $(this).css('left', '-100%'); // Nasconde il menu spostandolo fuori dallo schermo sinistro
        });

    });
</script>
<script>
    var swiper = new Swiper(".swiperServizi", {
        slidesPerView: 3,
        spaceBetween: 30,
        grabCursor: true,
        loop: true,
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false,
        // },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 3,
            },
        },
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        scrollbar: {
            el: ".swiper-scrollbar",
        },
    });
</script>