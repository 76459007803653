<header class="banner">
  <div class="container">
    <h1>IL NOSTRO BLOG</h1>
  </div>
</header>
<section>
  <div class="container">
    <div class="col-lg-6 mx-auto text-center">
      <p class="mb-0">Come prendersi cura del proprio veicolo? <br>Nel nostro blog troverai <b>consigli utili, novità e approfondimenti</b> sul mondo
        delle
        <b>riparazioni</b> e della
        <b>manutenzione automobilistica</b>.
      </p>
    </div>
  </div>
</section>
<section class="bg-blue">
  <div class="container">
    <div class="col-lg-10 mx-auto" *ngIf="loader==false">
      <div class="row mt-3 mb-4 article-grid">
        <a *ngFor="let blog of Listnews" class="text-decoration-none px-0" href="{{blog.Id}}/{{generateBlogLink(blog.Title)}}">
          <div class="article">
            <img src="{{blog.Base64}}" *ngIf="blog.Base64!=null">
            <img src="assets/img/no-image.png" *ngIf="blog.Base64==null">
            <h2>{{blog.Title}}</h2>
            <div class="pulsante-articolo">
              <a class="btnx">Leggi l'articolo</a>
              <img src="assets/img/arrow-right.svg" alt="leggi">
            </div>
          </div>
        </a>
      </div>
      <div class="d-flex flex-wrap justify-content-center align-items-center mt-2 mb-5" *ngIf="Listnews.length!=0 && pageLimit>1">
        <div class="pagination mt-2 mt-lg-0 align-items-center text-white">
          <a *ngIf="(pageIndex+1)>1" rel="prev" href="/pagina/{{pageIndex}}">
            <img src="assets/img/arrow-l.svg" alt="arrow" height="18" width="19" class="my-0 m-2">
          </a>
          <span><b>Pagina {{pageIndex + 1}}</b> di {{pageLimit}}</span>
          <a *ngIf="(pageIndex+1)<pageLimit" rel="next" href="/pagina/{{(pageIndex+2)}}">
            <img src="assets/img/arrow-r.svg" height="18" width="19" class="my-0 m-2">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container position-relative">
    <div class="d-flex justify-content-between">
      <h3 class="section-title">SERVIZI</h3>
      <p class="link-upper-section">
        <small>
          <a href="http://">Scoprili tutti
            <img src="assets/img/arrow-diagonalsvg.svg" alt="Scopri tutti" class="arrow-icon">
          </a>
        </small>
      </p>
    </div>

    <div class="col-lg-5">
      <p>Il nostro <b>centro Multiservice</b> offre una vasta gamma di servizi di alta qualità, tra cui riparazione della carrozzeria,
        cambio
        gomme
        e interventi di meccanica leggera.</p>
    </div>
    <swiper-container speed="500" loop="true" pagination="true" pagination-type="fraction" pagination-clickable="true" autoplay="true"
      scrollbar="true" class="swiperServiziAngular" space-between="30" [slidesPerView]="slidesPerView">
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/Meccanica.jpg" alt="Meccanica" class="img-fluid" loading="lazy">
        <h4>Meccanica</h4>
        <p><a href="https://www.teacarservice.it/servizi/meccanica.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/BodyCar.jpg" alt="CarrozeriaTeaCar" class="img-fluid" loading="lazy">
        <h4>Carrozzeria</h4>
        <p><a href="https://www.teacarservice.it/servizi/carrozzeria.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/CambioGomme.jpg" alt="Gomme" class="img-fluid" loading="lazy">
        <h4>Servizio Gomme</h4>
        <p><a href="https://www.teacarservice.it/servizi/gomme.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/CarroAttrezzi.jpg" alt="CarroAttrezzi" class="img-fluid" loading="lazy">
        <h4>Carro Attrezzi</h4>
        <p><a href="https://www.teacarservice.it/servizi/carro-attrezzi.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/SostituzioneCristalli.jpg" alt="Cristalli" class="img-fluid" loading="lazy">
        <h4>Riparazione Cristalli</h4>
        <p><a href="https://www.teacarservice.it/servizi/cristalli.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/NoleggioAuto.jpg" alt="NoleggioAuto" class="img-fluid" loading="lazy">
        <h4>Noleggio Auto</h4>
        <p><a href="https://www.teacarservice.it/servizi/noleggio-auto.html">Scopri di più</a></p>
      </swiper-slide>
      <swiper-slide class="text-center pt-5 slideServiziAngular">
        <img src="https://www.teacarservice.it/assets/img/Teacar-Delivery.jpg" alt="ServizioDelivery" class="img-fluid" loading="lazy">
        <h4>Delivery Point</h4>
        <p><a href="https://www.teacarservice.it/servizi/delivery-point.html">Scopri di più</a></p>
      </swiper-slide>
    </swiper-container>
    <script>
      // swiper element
      const swiperEl = document.querySelector('swiper-container');

      // swiper parameters
      const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 30,
        grabCursor: true,
        loop: true,
        autoplay: {
          delay: 500,
          disableOnInteraction: false,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          568: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      };


      setTimeout(() => {
        Object.assign(swiperEl, swiperParams);
        swiperEl.initialize();
      }, 5000);
    </script>
  </div>
</section>