import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Service {

  constructor(private http: HttpClient) { }

  url: string = "https://api.egonext.it/api";
  //url: string = "http://192.168.1.28/egonext.api/api";

  // appsettings: any = [];
  // getAppsettings() {
  //   const jsonUrl = './assets/appsettings.json'; // Adjust the URL to your JSON file
  //   this.fetchJson(jsonUrl).subscribe(
  //     data => {
  //       this.appsettings = data;
  //       this.url = this.appsettings.api;
  //       this.TokenUser = this.appsettings.tokenUser;
  //       this.tokenPass = this.appsettings.tokenPassword;
  //       document.documentElement.style.setProperty('--primary', this.appsettings.color);
  //       document.documentElement.style.setProperty('--box', this.appsettings.box_bottom_color);
  //     },
  //     error => {
  //       console.error('Error fetching JSON:', error);
  //     }
  //   );
  // }

  // fetchJson(url: string): Observable<any> {
  //   return this.http.get(url);
  // }

  tokenValue='';
  updatetoken(token) {
    this.tokenValue = token;
  }

  TokenUser='teacar';
  tokenPass='teacar';
  getToken() {
    const body = ({
      User: this.TokenUser,
      Password: this.tokenPass
    });
    return this.http.post(this.url + '/Token/GetToken', body)
  }

  LogErrors(ErrorMessage) {
    return this.http.get(this.url + '/LogErrors', {
      params: {
        ErrorMessage: ErrorMessage,
        Provenance: 'Blog Teacar',
        Company: 'Teacar Srl'
      }
    })
  }

  GetBlogs(cat, page, numItems, year, orderDate, search) {
    return this.http.get(this.url + '/Blog/GetBlog', {
      params: {
        Token: this.tokenValue,
        Id: cat,
        Year: year,
        // Month:null,
        DisplayWeb: true,
        data: JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: [
            {
              Field: "DtInsert",
              Dir: orderDate
            }
          ],
          Filter: {
            Field: "title",
            Operator: "contains",
            Value: search,
            Logic: "and"
          }
        }),
      }
    })
  }
  GetNumBlogCategory(cat, page, numItems, year, orderDate, search) {
    return this.http.get(this.url + '/Blog/GetNumBlogCategory', {
      params: {
        Token: this.tokenValue,
        Id: cat,
        Year: year,
        // Month:null,
        DisplayWeb: true,
        data: JSON.stringify({
          Offset: page,
          Limit: numItems,
          Sort: [
            {
              Field: "DtInsert",
              Dir: orderDate
            }
          ],
          Filter: {
            Field: "title",
            Operator: "contains",
            Value: search,
            Logic: "and"
          }
        }),
      }
    })
  }

  GetBlogDetails(id) {
    return this.http.get(this.url + '/Blog/GetBlogDetails', {
      params: {
        Token: this.tokenValue,
        Id: id
      }
    })
  }

  GetCategoriesblogs() {
    return this.http.get(this.url + '/Blog/GetCategories', {
      params: {
        Token: this.tokenValue
      }
    })
  }


}
